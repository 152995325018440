'use strict';

import Splide from '@splidejs/splide';

const { showProductTileSwatchImage, showProductTileAltImage, showProductTileFirstImage } = require('./search/search.js');

document.body.addEventListener('carousel:setup', () => {
    showProductTileSwatchImage();
    showProductTileAltImage();
    showProductTileFirstImage();
});

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 * @returns {Object} - einsteinUtils or null
 */
function getEinsteinUtils() {
    var einsteinUtils = window.CQuotient;
    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
}

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

/**
 * Initialize carousel
 * @param {javascript} parentElement parent element where recommendations will show.
 */
function carouselInit(parentElement) {
    let splide = new Splide(`#${parentElement.id}`, {
        perPage: parentElement.dataset.xs,
        perMove: 1,
        gap: 16,
        arrows: true,
        pagination: false,
        mediaQuery: 'min',
        breakpoints: {
            1220: {
                perPage: parentElement.dataset.md,
                perMove: 1,
                gap: 32
            },
            600: {
                perPage: parentElement.dataset.sm,
                perMove: 1
            }
        }
    });
    splide.mount();
    const carouselSetupEvent = new Event('carousel:setup');
    document.body.dispatchEvent(carouselSetupEvent);
}

/**
 * fills in the carousel with product tile html objects
 * @param {string} einsteinResponse string html for product tiles
 * @param {jQuery} parentElement parent element where recommendations will show.
 */
function fillDomElement(einsteinResponse, parentElement) {
    var recommender = parentElement.dataset.recommender;
    var recommendedProducts = einsteinResponse[recommender].recs;
    if (recommendedProducts && recommendedProducts.length > 0) {
        var template = parentElement.dataset.template;
        var swatches = (parentElement.dataset.swatches === 'true');
        var displayRatings = (parentElement.dataset.displayratings === 'true');
        var displayBadges = (parentElement.dataset.displaybadges === 'true');
        var components = [];
        components = recommendedProducts.map(function (recommendedProduct) {
            var tiledefinition = {};
            tiledefinition.classxs = parentElement.dataset.bsxs;
            tiledefinition.classsm = parentElement.dataset.bssm;
            tiledefinition.classmd = parentElement.dataset.bsmd;
            tiledefinition.template = template;
            tiledefinition.swatches = swatches;
            tiledefinition.displayratings = displayRatings;
            tiledefinition.displaybadges = displayBadges;
            tiledefinition.model = {
                type: 'product',
                id: recommendedProduct.id
            };
            return tiledefinition;
        });

        var url = new URL(parentElement.dataset.productLoadUrl);
        url.searchParams.append('components', JSON.stringify(components));
        url.searchParams.append('limit', parentElement.dataset.limit);
        url.searchParams.append('recommender', recommender);
        fetch(url.href).then(function (response) {
            return response.text();
        }).then(function (html) {
            let splideWrapper = parentElement.querySelector('.splide__list');
            splideWrapper.innerHTML = html;
            if (!parentElement.querySelector('.product-tile-pd')) {
                parentElement.closest('[class^="experience-component experience-einstein-einsteinCarousel"]').remove();
            } else {
                carouselInit(parentElement);
            }
        }).catch(function (err) {
            if (!parentElement.querySelector('.product-tile-pd')) {
                parentElement.closest('[class^="experience-component experience-einstein-einsteinCarousel"]').remove();
            }
        });
    } else {
        parentElement.closest('[class^="experience-component experience-einstein-einsteinCarousel"]').remove();
    }
}

/**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {jQuery} parentElement parent element where recommendations will show.
 * @param {Object} einsteinUtils cquotient object
 * @param {Array} anchorsArray array of objects representing anchors
 */
function processRecommendationsTile(parentElement, einsteinUtils, anchorsArray) {
    var recommender = parentElement.dataset.recommender;
    var params = {
        userId: einsteinUtils.getCQUserId(),
        cookieId: einsteinUtils.getCQCookieId(),
        ccver: '1.01'
    };

    if (anchorsArray) {
        params.anchors = anchorsArray;
    }

    /**
     * Processes a recommendation responses
     * @param {Object} einsteinResponse cquotient object
     */
    function recommendationsReceived(einsteinResponse) {
        fillDomElement(einsteinResponse, parentElement);
    }

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
        einsteinUtils.widgets.push({
            recommenderName: recommender,
            parameters: params,
            callback: recommendationsReceived
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized product specific anchors array
 * @param {jQuery} parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createProductAnchor(parentElement) {
    return [{
        id: parentElement.dataset.primaryProductId,
        sku: parentElement.dataset.secondaryProductId,
        type: parentElement.dataset.alternativeGroupType,
        alt_id: parentElement.dataset.alternativeGroupId
    }];
}

/**
 * Rerieves data attributes from parent element and converts to gretel compatible recommenders array
 * @param {jQuery} parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createCategoryAnchor(parentElement) {
    return [{
        id: parentElement.dataset.categoryId
    }];
}

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
function loadRecommendations() {
    const einsteinUtils = getEinsteinUtils();

    if (einsteinUtils) {
        let recommendationTiles = document.querySelectorAll('.einstein-carousel');

        recommendationTiles.forEach(function (recommendationTile) {
            let parentElement = recommendationTile;

            // parentElement.spinner().start();
            if (parentElement.closest('.experience-einstein-einsteinCarouselProduct')) {
                return processRecommendationsTile(parentElement, einsteinUtils, createProductAnchor(parentElement));
            } else if (parentElement.closest('.experience-einstein-einsteinCarouselCategory')) {
                return processRecommendationsTile(parentElement, einsteinUtils, createCategoryAnchor(parentElement));
            }
            return processRecommendationsTile(parentElement, einsteinUtils);
        });
    } else {
        let carousels = document.querySelectorAll('[class^="experience-component experience-einstein-einsteinCarousel"]');
        if (carousels) {
            carousels.forEach((carousel) => carousel.remove());
        }
    }
}

/**
 * Kicks off the necessary events to get Einstein recommendations
 */
function initializeEinstein() {
    if (getCookie('dw_dnt') == 0 || document.querySelector('.storepage').dataset.viewMode == false) {
        let cartRecommendationsM = document.getElementById('cart-recommendations-m');
        if (cartRecommendationsM) {
            // Wait for cart-recommendations-m to be populated by Einstein then initialize carousel
            const observer = new MutationObserver(mutationList => mutationList.filter(m => m.type === 'childList').forEach(m => {
                m.addedNodes.forEach(() => {
                    let parentElement = document.querySelector('.einstein-carousel');
                    carouselInit(parentElement);
                    observer.disconnect();
                });
            }));
            observer.observe(cartRecommendationsM, { childList: true, subtree: true });
        } else {
            loadRecommendations();
        }
    } else {
        let einsteinRecommendations = document.getElementById('einstein-recommendations');
        if (einsteinRecommendations) {
            einsteinRecommendations.parentElement.removeChild(einsteinRecommendations);
        }

        let carousels = document.querySelectorAll('[class^="experience-component experience-einstein-einsteinCarousel"]');
        if (carousels) {
            carousels.forEach((carousel) => carousel.remove());
        }
    }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
    initializeEinstein();
} else {
    window.addEventListener('DOMContentLoaded', () => {
        initializeEinstein();
    });
}

